<template>
  <div class="content-wrapper">
    <div class="panel max-w-1200 mx-auto">
      <p class="panel-heading">
        {{ !user ? $t('user-invite-title') : $t('user-invite-to-projects') }}
      </p>
      <div class="panel-block p-5">
        <VAsync v-slot="promise" :await="request" @reject="inviteUserFailed">
          <div>
            <BLoading v-if="promise.pending" active />

            <VForm
              v-if="!user"
              v-slot="form"
              :action="$options.apiInvite"
              method="POST"
              @submit.prevent="onCreateUser"
            >
              <BField :label="$t('firstname')">
                <BInput name="firstname" required />
              </BField>

              <BField :label="$t('lastname')">
                <BInput name="lastname" required />
              </BField>

              <BField :label="$t('email')">
                <BInput name="email" type="email" required />
              </BField>

              <BField :label="$t('username')">
                <BInput name="username" required minlength="2" />
              </BField>

              <BField v-if="currentUser.adminByNow" :label="$t('role')">
                <div class="control select w-full">
                  <select name="role">
                    <option value="USER">
                      {{ $t('user') }}
                    </option>
                    <option value="ADMIN">
                      {{ $t('admin') }}
                    </option>
                    <option value="SUPER_ADMIN">
                      {{ $t('super-admin') }}
                    </option>
                  </select>
                </div>
              </BField>

              <IdxBtn
                type="submit"
                class="mt-5"
                :color="form.valid ? 'primary' : ''"
                :disabled="!form.valid"
              >
                {{ $t('save') }}
              </IdxBtn>
            </VForm>

            <form v-else @submit.prevent="onAddUserToProject">
              <fieldset>
                <div class="max-h-480 overflow-auto">
                  <div v-for="project in projects" :key="project.id">
                    <BField>
                      <BCheckbox
                        v-model="selectedProjects"
                        :native-value="project"
                        type="is-info"
                      >
                        {{ project.name }}
                      </BCheckbox>
                    </BField>
                  </div>
                </div>
              </fieldset>

              <IdxBtn type="submit" class="mt-5" color="primary">
                {{ $t('save') }}
              </IdxBtn>
            </form>
          </div>
        </VAsync>
      </div>
    </div>
  </div>
</template>

<script>
import noteApi from '../../services/noteApi.js';

export default {
  data: () => ({
    request: undefined,
    projects: [],
    user: undefined,
    selectedProjects: [],
  }),
  computed: {
    currentUser() {
      return this.$store.state.currentUser.user;
    },
  },

  methods: {
    onCreateUser({ target: form }) {
      this.request = async () => {
        const formData = new FormData(form);

        const user = await noteApi.post('/napi/user/invite', {
          json: Object.fromEntries(formData),
        });

        this.$notify({
          type: 'success',
          text: `An invite has been sent to ${user.email}!`,
        });

        if (this.currentUser.admin) {
          const projects = await noteApi
            .get(
              // Legacy Cytomine client had a strange way of structuring query params, so we do the same.
              '/napi/project?withCurrentUserRoles=true&currentUserRole%5Bin%5D=manager'
            )
            .then((res) => res.collection);

          if (projects.length) {
            this.user = user;
            this.projects = projects;
          }
        }
      };
    },

    onAddUserToProject() {
      this.request = async () => {
        const { user } = this;
        const requests = this.selectedProjects.map((project) => {
          return noteApi.post(
            `/api/project/${project.id}/user.json?users=${user.id}`
          );
        });

        if (requests.length) {
          await Promise.all(requests);

          // send email invite to user
          noteApi.post(`/napi/project/invite`, {
            json: {
              to: [user.email],
              projects: this.selectedProjects.map((project) => ({
                projectName: project.name,
                projectLink: `${window.location.origin}/#/project/${project.id}`,
              })),
            },
          });

          this.$notify({
            type: 'success',
            text: `${user.username} added to ${this.selectedProjects.length} project(s).`,
          });
        }

        this.user = undefined;
      };
    },

    inviteUserFailed(error) {
      this.$notify({
        type: 'error',
        text: error?.message ?? this.$t('user-create-fail'),
      });
    },
  },
};
</script>
