<template>
  <div class="content-wrapper pb-5">
    <div class="panel">
      <p class="panel-heading">
        <i class="fas fa-user" aria-hidden="true" />
        {{ $t('profile') }}
      </p>
      <div class="panel-block">
        <form data-vv-scope="profile" @submit.prevent="editDetails()">
          <BMessage
            v-if="currentUser.passwordExpired"
            type="is-danger"
            has-icon
            icon-size="is-small"
          >
            {{ $t('password-expired-info-message') }}
          </BMessage>

          <BField :label="$t('username')" horizontal>
            <BInput :value="currentUser.username" disabled />
          </BField>

          <BField :label="$t('role')" horizontal>
            <span :class="role.class" class="tag">{{ $t(role.label) }}</span>
          </BField>

          <BField
            :label="$t('lastname')"
            :type="{ 'is-danger': errors.has('profile.lastname') }"
            :message="errors.first('profile.lastname')"
            horizontal
          >
            <BInput
              v-model="updatedUser.lastname"
              v-validate="'required'"
              name="lastname"
            />
          </BField>

          <BField
            :label="$t('firstname')"
            :type="{ 'is-danger': errors.has('profile.firstname') }"
            :message="errors.first('profile.firstname')"
            horizontal
          >
            <BInput
              v-model="updatedUser.firstname"
              v-validate="'required'"
              name="firstname"
            />
          </BField>

          <BField
            :label="$t('email')"
            :type="{ 'is-danger': errors.has('profile.email') }"
            :message="errors.first('profile.email')"
            horizontal
          >
            <BInput
              v-model="updatedUser.email"
              v-validate="'required|email'"
              name="email"
            />
          </BField>

          <template v-if="currentUser.passwordExpired">
            <input id="password" class="hidden" type="password" />
            <!-- HACK: fake field to prevent autofill -->

            <BField
              :label="$t('password-new')"
              :type="{ 'is-danger': errors.has('profile.newPassword') }"
              :message="errors.first('profile.newPassword')"
              horizontal
            >
              <BInput
                v-model="newPassword"
                v-validate="'required|min:8'"
                type="password"
                name="newPassword"
              />
            </BField>

            <BField
              :label="$t('password-confirm')"
              :type="{ 'is-danger': errors.has('profile.confirmPassword') }"
              :message="errors.first('profile.confirmPassword')"
              horizontal
            >
              <BInput
                v-model="confirmPassword"
                v-validate="{ required: true, is: newPassword }"
                type="password"
                name="confirmPassword"
              />
            </BField>
          </template>

          <!--
          <BField :label="$t('language')" horizontal>
            <BSelect v-model="updatedUser.language">
              <option v-for="{value, name} in languages" :key="value" :value="value">
                {{name}}
              </option>
            </BSelect>
          </BField>
          -->

          <BField grouped position="is-right">
            <div class="control">
              <button :disabled="errors.any('profile')" class="button is-link">
                {{ $t('save') }}
              </button>
            </div>
          </BField>
        </form>
      </div>
    </div>

    <div v-if="!currentUser.passwordExpired" class="panel">
      <p class="panel-heading">
        <i class="fas fa-briefcase" aria-hidden="true" />
        {{ $t('password') }}
      </p>
      <div class="panel-block">
        <form data-vv-scope="password" @submit.prevent="savePassword()">
          <input id="password" class="hidden" type="password" />
          <!-- HACK: fake field to prevent autofill -->

          <BField
            :label="$t('password-current')"
            :type="typeCurrentPassword"
            :message="messageCurrentPassword"
            horizontal
          >
            <BInput
              v-model="currentPassword"
              :loading="isCheckingPassword"
              type="password"
            />
          </BField>

          <BField
            :label="$t('password-new')"
            :type="{ 'is-danger': errors.has('password.newPassword') }"
            :message="errors.first('password.newPassword')"
            horizontal
          >
            <BInput
              v-model="newPassword"
              v-validate="'required|min:8'"
              :disabled="newPasswordDisabled"
              type="password"
              name="newPassword"
            />
          </BField>

          <BField
            :label="$t('password-confirm')"
            :type="{ 'is-danger': errors.has('password.confirmPassword') }"
            :message="errors.first('password.confirmPassword')"
            horizontal
          >
            <BInput
              v-model="confirmPassword"
              v-validate="{ required: true, is: newPassword }"
              :disabled="newPasswordDisabled"
              type="password"
              name="confirmPassword"
            />
          </BField>

          <BField grouped position="is-right">
            <div class="control">
              <button
                :disabled="errors.any('password') || newPasswordDisabled"
                class="button is-link"
              >
                {{ $t('save') }}
              </button>
            </div>
          </BField>
        </form>
      </div>
    </div>

    <div v-if="currentUser.adminByNow" class="panel">
      <p class="panel-heading">
        <i class="fas fa-exchange-alt" aria-hidden="true" />
        {{ $t('api-keys') }}
      </p>
      <div class="panel-block">
        <BField :label="$t('public-key')" horizontal>
          <BField>
            <BInput :value="currentUser.publicKey" readonly expanded />
            <p class="control">
              <button class="button" @click="copy(currentUser.publicKey)">
                <span class="icon"><i class="far fa-clipboard" /></span>
                <span>{{ $t('button-copy') }}</span>
              </button>
            </p>
          </BField>
        </BField>

        <BField :label="$t('private-key')" horizontal>
          <BField>
            <BInput :value="currentUser.privateKey" readonly expanded />
            <p class="control">
              <button class="button" @click="copy(currentUser.privateKey)">
                <span class="icon"><i class="far fa-clipboard" /></span>
                <span>{{ $t('button-copy') }}</span>
              </button>
            </p>
          </BField>
        </BField>

        <BField grouped position="is-right">
          <div class="control">
            <button class="button is-link" @click="regenerateKeys()">
              {{ $t('regenerate-api-keys') }}
            </button>
          </div>
        </BField>
      </div>
    </div>

    <ManageUsers />

    <div class="panel">
      <p class="panel-heading">
        <i class="fas fa-chart-bar" aria-hidden="true" />
        {{ $t('statistics') }}
      </p>
      <div class="panel-block">
        <table class="table is-fullwidth">
          <tbody>
            <tr>
              <td>{{ projects ? projects.length : '?' }}</td>
              <td>{{ $t('studies') }}</td>
              <td>
                <v-popover>
                  <i class="fas fa-info-circle" />
                  <template #popover>
                    <p>{{ $t('projects-number-info-message') }}</p>
                  </template>
                </v-popover>
              </td>
            </tr>
            <tr>
              <td>{{ nbImages != null ? nbImages : '?' }}</td>
              <td>{{ $t('images') }}</td>
              <td>
                <v-popover>
                  <i class="fas fa-info-circle" />
                  <template #popover>
                    <p>{{ $t('number-images-info-message') }}</p>
                  </template>
                </v-popover>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { ProjectCollection, User } from 'cytomine-client';
import debounce from 'lodash/debounce';
import copyToClipboard from 'copy-to-clipboard';
import ManageUsers from './ManageUsers';
import { changeLanguageMixin } from '@/lang.js';
import { rolesMapping } from '@/utils/role-utils';

export default {
  name: 'Account',
  components: {
    ManageUsers,
  },
  $_veeValidate: { validator: 'new' },
  mixins: [changeLanguageMixin],
  data() {
    return {
      updatedUser: this.$store.state.currentUser.user.clone(),
      currentPassword: '',
      isCheckingPassword: false,
      correctPassword: false,
      newPassword: '',
      confirmPassword: '',
      projects: [],
      languages: [
        {
          value: 'EN',
          name: 'English',
        },
        {
          value: 'FR',
          name: 'Français',
        },
      ],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    role() {
      const key = this.currentUser.guestByNow
        ? 'ROLE_GUEST'
        : this.currentUser.admin
        ? 'ROLE_ADMIN'
        : 'ROLE_USER';
      return rolesMapping[key];
    },
    newPasswordDisabled() {
      return !this.correctPassword && !this.currentUser.passwordExpired;
    },
    messageCurrentPassword() {
      if (!this.currentPassword || this.isCheckingPassword) {
        return;
      }
      return this.correctPassword ? '' : this.$t('invalid-password');
    },
    typeCurrentPassword() {
      if (!this.currentPassword || this.isCheckingPassword) {
        return;
      }
      return this.correctPassword ? 'is-success' : 'is-danger';
    },
    /** @returns {number} */
    nbImages() {
      if (!this.projects) {
        return 0;
      }

      return this.projects.reduce((count, project) => {
        return count + project.numberOfImages;
      }, 0);
    },
  },
  watch: {
    currentPassword() {
      this.isCheckingPassword = true;
      this.checkPassword();
    },
  },
  async created() {
    await this.fetchProjects();
    await this.$validator.validateAll('profile');
  },
  methods: {
    async editDetails() {
      const result = await this.$validator.validateAll('profile');
      if (!result) {
        return;
      }

      if (this.currentUser.passwordExpired) {
        this.updatedUser.password = this.newPassword;
      }

      try {
        await this.$store.dispatch('currentUser/updateUser', this.updatedUser);
        this.changeLanguage(this.currentUser.language);
        this.$notify({
          type: 'success',
          text: this.$t('notif-success-user-details-saved'),
        });

        if (this.currentUser.passwordExpired) {
          this.currentPassword = '';
          this.newPassword = '';
          this.confirmPassword = '';
          this.$validator.reset({ scope: 'password' });
        }
        this.$validator.reset({ scope: 'profile' });
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-user-details-not-saved'),
        });
      }
    },

    async savePassword() {
      const result = await this.$validator.validateAll('password');
      if (
        !result ||
        (!this.correctPassword && !this.currentUser.passwordExpired)
      ) {
        return;
      }

      const userNewPassword = this.currentUser.clone();
      userNewPassword.password = this.newPassword;
      try {
        await this.$store.dispatch('currentUser/updateUser', userNewPassword);
        this.$notify({
          type: 'success',
          text: this.$t('notif-success-password-saved'),
        });
        this.currentPassword = '';
        this.newPassword = '';
        this.confirmPassword = '';
        this.$validator.reset({ scope: 'password' });
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-password-not-saved'),
        });
      }
    },

    async fetchProjects() {
      const projects = await ProjectCollection.fetchAll();
      this.projects = projects?.array;
    },

    checkPassword: debounce(async function() {
      this.correctPassword = await User.checkCurrentPassword(
        this.currentPassword
      );
      if (!this.correctPassword) {
        this.newPassword = '';
        this.confirmPassword = '';
        this.$validator.reset({ scope: 'password' });
      }
      this.isCheckingPassword = false;
    }, 500),

    copy(value) {
      copyToClipboard(value);
      this.$notify({
        type: 'success',
        text: this.$t('notif-success-key-copied'),
      });
    },

    async regenerateKeys() {
      try {
        await this.$store.dispatch('currentUser/regenerateKeys');
        this.$notify({
          type: 'success',
          text: this.$t('notif-success-keys-regenerated'),
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-keys-not-regenerated'),
        });
      }
    },
  },
};
</script>

<style scoped>
.panel {
  max-width: 80em;
  margin-left: auto;
  margin-right: auto;
}

.fas {
  margin-right: 0.3em;
}

input.hidden {
  display: none;
}
</style>
