<template>
  <div class="panel">
    <p class="panel-heading">
      <i class="fas fa-user-friends" aria-hidden="true" />
      {{ $t('users-manage') }}
    </p>
    <div class="panel-block">
      <BLoading :is-full-page="false" :active="loading" />
      <template v-if="!loading">
        <div class="columns">
          <div class="column is-one-quarter">
            <BInput
              v-model="searchString"
              :placeholder="$t('search')"
              type="search"
              icon="search"
            />
          </div>

          <div class="column is-one-half has-text-right">
            <RouterLink
              class="button is-link has-text-white has-background-link"
              to="/account/invite-user"
            >
              {{ $t('user-invite') }}
            </RouterLink>
          </div>
        </div>

        <IdxTable
          :await="fetchUsers"
          :data="users"
          :current-page.sync="currentPage"
          :per-page.sync="perPage"
          :sort-by.sync="sortField"
          :sort-direction.sync="sortOrder"
        >
          <template #default>
            <BTableColumn
              v-slot="props"
              :label="$t('username')"
              field="username"
              sortable
              width="100"
            >
              {{ props.row.username }}
            </BTableColumn>

            <BTableColumn
              v-slot="props"
              :label="$t('name')"
              field="fullName"
              sortable
              width="150"
            >
              {{ props.row.firstname }} {{ props.row.lastname }}
            </BTableColumn>

            <BTableColumn
              v-slot="props"
              :label="$t('email')"
              field="email"
              sortable
              width="150"
            >
              <a :href="`mailto:${props.row.email}`">{{ props.row.email }}</a>
            </BTableColumn>

            <BTableColumn v-slot="props" label="" width="100">
              <div class="buttons">
                <button
                  class="button is-danger is-small"
                  @click="remove(props.row)"
                >
                  {{ $t('delete') }}
                </button>
              </div>
            </BTableColumn>
          </template>

          <template #empty>
            <div class="content has-text-grey has-text-centered">
              <p>{{ $t('no-user-fitting-criteria') }}</p>
            </div>
          </template>
        </IdxTable>
      </template>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import noteApi from '@/services/noteApi';
import IdxTable, { DEFAULTS } from '@/components/utils/IdxTable';

export default {
  name: 'ManageUsers',
  components: {
    IdxTable,
  },
  data() {
    return {
      fetchUsers: null,
      loading: false,
      searchString: '',
      currentPage: 1,
      perPage: DEFAULTS.perPage,
      sortField: 'username',
      sortOrder: '',
      users: [],
    };
  },
  watch: {
    searchString: {
      handler: debounce(function() {
        this.fetchList();
      }, 300),
      immediate: true,
    },
  },
  methods: {
    fetchList() {
      this.fetchUsers = async () => {
        const data = await noteApi.get('/napi/user/invite', {
          query: {
            'fullName[ilike]': this.searchString,
          },
        });

        this.users = data.collection;
      };
    },
    async remove(user) {
      try {
        await noteApi.delete(`/napi/user/${user.id}`);
        this.fetchList();
        this.$notify({
          type: 'success',
          text: this.$t('user-delete-success'),
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('user-delete-fail'),
        });
      }
    },
  },
};
</script>
